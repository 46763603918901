<template>
  <div
    class="modal fade modal-data-pack"
    ref="modalNotice"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-hidden="true"
    data-bs-backdrop="static"
  >
    <div
      class="
        modal-dialog modal-dialog-centered modal-dialog-scrollable modal-sm
      "
    >
      <div class="modal-content">
        <form @submit.prevent="verifyOtp">
          <!-- <div class="modal-header pb-1">
          <h5 class="text-blue">Reminder</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div> -->
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <p>
                  To confirm your application, we sent an OTP to your mobile
                  number. Please enter the 6-digit OTP here.
                </p>

                <div>
                  <div class="input-group mb-3">
                    <input
                      class="form-control"
                      placeholder="******"
                      v-mask="'######'"
                      masked="false"
                      type="tel"
                      aria-label="One-time password"
                      aria-describedby="button-addon2"
                      v-model="otp"
                      required
                    />
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      id="button-addon2"
                      :disabled="otpResendCounter > 0"
                      @click.prevent="sendOtp(msisdn)"
                    >
                      Resend OTP
                      <span v-if="otpResendCounter"
                        >({{ otpResendCounter }})</span
                      >
                    </button>
                  </div>
                </div>

                <p class="mt-3">
                  Note: Unconfirmed application will not be processed
                </p>
              </div>
            </div>
          </div>
          <div class="modal-footer border-0">
            <button
              type="submit"
              :disabled="verifyOtpBtn"
              class="btn btn-s2s-blue primary"
            >
              <span
                v-if="verifyOtpBtn"
                class="spinner-border spinner-border-sm pl-5"
                role="status"
              >
                <span class="visually-hidden">Loading...</span>
              </span>
              Verify
            </button>
          </div>
        </form>
      </div>
    </div>
    <Toast ref="toast" bgClass="bg-danger" position="top-0 end-0" />
  </div>
</template>

<script>
import { Modal } from "bootstrap";
import Toast from "../components/Toast.vue";
export default {
  components: {
    Toast,
  },
  data() {
    return {
      availableAreas: [
        // {
        //   municipalities: ["All"],
        //   province: "Metro Manila",
        //   region: "NCR",
        // },
        // {
        //   municipalities: ["Meycauayan", "Marilao", "Bocaue", "Santa Maria"],
        //   province: "Bulacan",
        //   region: "Central Luzon",
        // },
        // {
        //   municipalities: [
        //     "Tayabas",
        //     "Lucena",
        //     "Lucban",
        //     "Sariaya",
        //     "Candelaria",
        //     "Pagbilao",
        //   ],
        //   province: "Quezon",
        //   region: "Calabarzon",
        // },
        // {
        //   municipalities: ["Naga"],
        //   province: "Camarines Sur",
        //   region: "Bicol",
        // },
      ],
      background: "bg-f30",
      modal: null,
      resolve: null,
      reject: null,

      modals: {
        mobileNumber: null,
      },

      skus: [],
      selectedSku: {},

      mobileNumber: "",
      submitted: false,

      accordion: null,

      verifyOtpBtn: false,
      otp: "", // new
      otpResendCounter: 0,
      interval: null,

      transId: "",
      msisdn: "",
    };
  },
  methods: {
    selectSku(item) {
      this.$store
        .dispatch("ADD_DATAPACK", item)
        .then(() => {
          this.modal.hide();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    open(msisdn) {
      // const sku = this.$store.getters.GET_DATAPACK;

      // if (Object.keys(sku).length === 0) {
      //   // this.getLocation();
      //   // console.log("Asdasda");
      //   this.modal.show();
      // }

      // if (force) {
      //   this.modal.show();
      // }
      console.log(msisdn);
      this.msisdn = this.reFormatNumber(msisdn);
      this.sendOtp(this.msisdn);
      this.modal.show();

      // this.title = title;
      // this.message = message;
      // this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    reFormatNumber(num) {
      // return num.replace(/\s+/g, "").replace("0", "");
      if (num && num.indexOf("63") == 0) {
        return num.replace(/\s+/g, "");
      }
      return "63" + num.substring(1).replace(/\s+/g, "");
    },

    agree() {
      this.resolve(true);
      // this.dialog = false;
      this.modal.hide();
    },
    cancel() {
      this.resolve(false);
      // this.dialog = false;
      this.modal.hide();
    },

    async verifyOtp() {
      await this.$sms
        .post("/Otps/ValidOTP", {
          trxnID: this.transId,
          serviceID: 1009,
          otp: this.otp,
        })
        .then((response) => {
          const res = response.data;
          console.log(response);

          if (!res.status) {
            this.$refs.toast.open({ message: res.message });
            return;
          }
          this.$store.dispatch("ADD_OTP_VERIFIED", true).then(() => {
            this.agree();
          });

          // console.log(response);

          // this.otpCounter = 300;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async sendOtp(mobileNumber) {
      await this.$sms
        .post("/Otps/SendOTP", {
          msisdn: mobileNumber,
          serviceID: 1009,
        })
        .then((response) => {
          const res = response.data;
          this.transId = res.trxnid;

          this.otpResendCounter = 300;
          console.log(response);
          this.otpCounter();
        })
        .catch((e) => {
          console.log(e);
        });
    },

    otpCounter() {
      console.log("start");
      this.interval = setInterval(() => {
        // miliseconds -= 1;
        // this.computeTime(miliseconds);
        if (this.otpResendCounter <= 0) {
          clearInterval(this.interval);
          return;
        }

        this.otpResendCounter = this.otpResendCounter - 1;
      }, 1000);
    },
  },
  mounted() {
    this.modal = new Modal(this.$refs.modalNotice, {
      keyboard: false,
    });
  },

  computed: {
    isActive() {
      const sku = this.$store.getters.GET_DATAPACK;
      var def = { skuId: 0 };
      if (Object.keys(sku).length != 0) {
        return sku;
      }

      return def;
    },
  },

  created() {
    // this.getAvailableAreas();
  },
};
</script>

<style scoped>
.accordion-button {
  padding: 10px 10px;
}
</style>
