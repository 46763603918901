<template>
  <div class="container h-100">
    <!-- <div>all</div> -->
    <div class="row h-100 justify-content-center align-items-center">
      <div class="col-lg-5">
        <div class="card card-custom shadow">
          <div class="card-body p-0">
            <div class="row">
              <div class="col-lg-12 my-5">
                <div class="mx-4">
                  <h4 class="text-center fw-bolder text-navy-blue">
                    Area is Serviceable
                  </h4>
                  <div class="text-center mt-3">
                    <strong>Congratulations!</strong>
                    Your area is serviceable. To continue with your application,
                    fill up the necessary information.
                  </div>
                  <div class="my-5">
                    <div>
                      <div class="mb-3" v-if="stepOneDone && !this.getIsCod">
                        <label class="form-label">Reference Number</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Reference Number"
                          :value="getRefNumber"
                          readonly
                        />
                      </div>

                      <div class="mb-3">
                        <label class="form-label">First Name *</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="First Name"
                          v-model="form.firstName"
                          autocomplete="off"
                          name="firstName"
                          :class="{
                            'is-invalid': submitted && errors.has('firstName'),
                          }"
                          v-validate="'required'"
                        />
                        <div
                          v-if="submitted && errors.has('firstName')"
                          class="invalid-feedback"
                        >
                          This field is required
                        </div>
                      </div>

                      <div class="mb-3">
                        <label class="form-label">Middle Name</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Middle Name"
                          v-model="form.middleName"
                          name="middleName"
                          autocomplete="off"
                        />
                      </div>

                      <div class="mb-3">
                        <label class="form-label">Last Name *</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Last Name"
                          v-model="form.lastName"
                          name="lastName"
                          autocomplete="off"
                          :class="{
                            'is-invalid': submitted && errors.has('lastName'),
                          }"
                          v-validate="'required'"
                        />
                        <div
                          v-if="submitted && errors.has('lastName')"
                          class="invalid-feedback"
                        >
                          This field is required
                        </div>
                      </div>

                      <div class="mb-3 text-center" v-if="!stepOneDone">
                        <button
                          class="btn px-5 btn-s2s-blue"
                          :disabled="btnLoading"
                          @click.prevent="portReserve()"
                        >
                          <span
                            v-if="btnLoading"
                            class="spinner-border spinner-border-sm pl-5"
                            role="status"
                          >
                            <span class="visually-hidden">Loading...</span>
                          </span>
                          Next
                        </button>
                      </div>
                    </div>

                    <div v-if="stepOneDone">
                      <div class="mb-3 row">
                        <label class="form-label">Birthday *</label>
                        <div class="col-4">
                          <label class="form-label">Month *</label>
                          <select
                            class="form-control"
                            name="month"
                            v-model="birthday.month"
                            :class="{
                              'is-invalid': submitted && errors.has('month'),
                            }"
                            v-validate="'required'"
                          >
                            <option value="" selected disabled hidden>
                              Select Month
                            </option>
                            <option
                              v-for="(month, i) in months"
                              :key="i"
                              :value="month.value"
                              v-text="month.name"
                            ></option>
                          </select>
                          <div
                            v-if="submitted && errors.has('month')"
                            class="invalid-feedback"
                          >
                            This field is required
                          </div>
                        </div>
                        <div class="col-4">
                          <label class="form-label">Date *</label>
                          <select
                            class="form-control"
                            name="date"
                            v-model="birthday.date"
                            :class="{
                              'is-invalid': submitted && errors.has('date'),
                            }"
                            v-validate="'required'"
                          >
                            <option value="" selected disabled hidden>
                              Select Date
                            </option>
                            <option
                              v-for="i in 31"
                              :key="i"
                              :value="i"
                              v-text="i"
                            ></option>
                          </select>
                          <div
                            v-if="submitted && errors.has('date')"
                            class="invalid-feedback"
                          >
                            This field is required
                          </div>
                        </div>
                        <div class="col-4">
                          <label class="form-label">Year *</label>
                          <select
                            class="form-control"
                            name="year"
                            v-model="birthday.year"
                            :class="{
                              'is-invalid': submitted && errors.has('year'),
                            }"
                            v-validate="'required'"
                          >
                            <option value="" selected disabled hidden>
                              Select Year
                            </option>
                            <option
                              v-for="year in years"
                              :key="year"
                              :value="year"
                              v-text="year"
                            ></option>
                          </select>
                          <div
                            v-if="submitted && errors.has('year')"
                            class="invalid-feedback"
                          >
                            This field is required
                          </div>
                        </div>
                      </div>

                      <!-- <div class="mb-3">
                        <label class="form-label">Gender *</label>
                        <select class="form-control" v-model="form.gender">
                          <option value="" selected disabled hidden>
                            Select Gender
                          </option>
                          <option value="MALE">Male</option>
                          <option value="FEMALE">Female</option>
                        </select>
                      </div> -->

                      <!-- <div class="mb-3">
                        <label class="form-label">Civil Status *</label>
                        <select
                          class="form-control"
                          name="civilStatus"
                          v-model="form.civilStatus"
                          :class="{
                            'is-invalid':
                              submitted && errors.has('civilStatus'),
                          }"
                          v-validate="'required'"
                        >
                          <option value="" selected disabled hidden>
                            Select Civil Status
                          </option>
                          <option
                            v-for="(status, i) in civilStatus"
                            :key="i"
                            :value="status"
                            v-text="status"
                          ></option>
                        </select>
                        <div
                          v-if="submitted && errors.has('civilStatus')"
                          class="invalid-feedback"
                        >
                          This field is required
                        </div>
                      </div> -->

                      <div class="mb-3">
                        <label class="form-label">Contact Number *</label>
                        <input
                          v-mask="'09 ### ### ###'"
                          type="tel"
                          name="mobileNumber"
                          autocomplete="off"
                          class="form-control"
                          placeholder="09 XXX XXX XXX"
                          v-model="form.mobileNumber"
                          :class="{
                            'is-invalid':
                              submitted && errors.has('mobileNumber'),
                          }"
                          v-validate="'required'"
                        />
                        <div
                          v-if="submitted && errors.has('mobileNumber')"
                          class="invalid-feedback"
                        >
                          This field is required
                        </div>
                      </div>

                      <div class="mb-3">
                        <label class="form-label">Email Address *</label>
                        <input
                          type="email"
                          class="form-control"
                          placeholder="Email Address"
                          autocomplete="off"
                          v-model="form.emailAddress"
                          name="emailAddress"
                          :class="{
                            'is-invalid':
                              submitted && errors.has('emailAddress'),
                          }"
                          v-validate="'required'"
                        />
                        <div
                          v-if="submitted && errors.has('emailAddress')"
                          class="invalid-feedback"
                        >
                          This field is required
                        </div>
                      </div>

                      <!-- <div class="mb-3">
                        <label class="form-label">Mother's First Name *</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Mother's First Name"
                          v-model="form.motherFirstName"
                          name="motherFirstName"
                          :class="{
                            'is-invalid':
                              submitted && errors.has('motherFirstName'),
                          }"
                          v-validate="'required'"
                        />
                        <div
                          v-if="submitted && errors.has('motherFirstName')"
                          class="invalid-feedback"
                        >
                          This field is required
                        </div>
                      </div>

                      <div class="mb-3">
                        <label class="form-label">Mother's Last Name *</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Mother's Last Name"
                          v-model="form.motherLastName"
                          name="motherLastName"
                          :class="{
                            'is-invalid':
                              submitted && errors.has('motherLastName'),
                          }"
                          v-validate="'required'"
                        />
                        <div
                          v-if="submitted && errors.has('motherLastName')"
                          class="invalid-feedback"
                        >
                          This field is required
                        </div>
                      </div>

                      <div class="mb-3">
                        <label class="form-label">Mother's Middle Name *</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Mother's Middle Name"
                          v-model="form.motherMiddleName"
                          name="motherMiddleName"
                          :class="{
                            'is-invalid':
                              submitted && errors.has('motherMiddleName'),
                          }"
                          v-validate="'required'"
                        />
                        <div
                          v-if="submitted && errors.has('motherMiddleName')"
                          class="invalid-feedback"
                        >
                          This field is required
                        </div>
                      </div> -->

                      <!-- <div class="mb-3">
                        <label class="form-label">Area *</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Area"
                          v-model="form.area"
                          name="area"
                          :class="{
                            'is-invalid': submitted && errors.has('area'),
                          }"
                          v-validate="'required'"
                        />
                        <div
                          v-if="submitted && errors.has('area')"
                          class="invalid-feedback"
                        >
                          This field is required
                        </div>
                      </div> -->

                      <div class="mb-3">
                        <h6><strong>Address</strong></h6>
                      </div>

                      <div class="mb-3">
                        <label class="form-label">Address *</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Address"
                          v-model="form.address"
                          name="address"
                          :class="{
                            'is-invalid': submitted && errors.has('address'),
                          }"
                          v-validate="'required'"
                        />
                        <div
                          v-if="submitted && errors.has('address')"
                          class="invalid-feedback"
                        >
                          This field is required
                        </div>
                      </div>

                      <div class="mb-3">
                        <label class="form-label">Village *</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Village"
                          v-model="form.village"
                          name="village"
                          :class="{
                            'is-invalid': submitted && errors.has('village'),
                          }"
                          v-validate="'required'"
                        />
                        <div
                          v-if="submitted && errors.has('village')"
                          class="invalid-feedback"
                        >
                          This field is required
                        </div>
                      </div>

                      <div class="mb-3">
                        <label class="form-label">Region *</label>
                        <select
                          class="form-control"
                          name="region"
                          :class="{
                            'is-invalid': submitted && errors.has('region'),
                          }"
                          v-validate="'required'"
                          @change.prevent="regionSelected($event)"
                          :disabled="location.regions < 1"
                          v-model="form.region"
                        >
                          <option value="" selected disabled hidden>
                            Select Region
                          </option>
                          <option
                            v-for="(region, i) in location.regions"
                            :key="i"
                            :value="region.name"
                            :data-id="region._id"
                            v-text="region.name"
                          ></option>
                        </select>
                        <div
                          v-if="submitted && errors.has('region')"
                          class="invalid-feedback"
                        >
                          This field is required
                        </div>
                      </div>

                      <div class="mb-3">
                        <label class="form-label">Province *</label>
                        <select
                          class="form-control"
                          name="province"
                          :class="{
                            'is-invalid': submitted && errors.has('province'),
                          }"
                          v-validate="'required'"
                          @change.prevent="provinceSelected($event)"
                          :disabled="location.provinces < 1"
                          v-model="form.province"
                        >
                          <option value="" selected disabled hidden>
                            Select Province
                          </option>
                          <option
                            v-for="(province, i) in location.provinces"
                            :key="i"
                            :value="province.name"
                            :data-id="province._id"
                            v-text="province.name"
                          ></option>
                        </select>
                        <div
                          v-if="submitted && errors.has('province')"
                          class="invalid-feedback"
                        >
                          This field is required
                        </div>
                      </div>

                      <div class="mb-3">
                        <label class="form-label">City / Municipal *</label>
                        <select
                          class="form-control"
                          name="city"
                          :class="{
                            'is-invalid': submitted && errors.has('city'),
                          }"
                          v-validate="'required'"
                          @change.prevent="citySelected($event)"
                          :disabled="location.cities < 1"
                          v-model="form.city"
                        >
                          <option value="" selected disabled hidden>
                            Select City
                          </option>
                          <option
                            v-for="(city, i) in location.cities"
                            :key="i"
                            :value="city.name"
                            :data-id="city._id"
                            v-text="city.name"
                          ></option>
                        </select>
                        <div
                          v-if="submitted && errors.has('city')"
                          class="invalid-feedback"
                        >
                          This field is required
                        </div>
                      </div>

                      <div class="mb-3">
                        <label class="form-label">Barangay *</label>
                        <select
                          class="form-control"
                          name="barangay"
                          :class="{
                            'is-invalid': submitted && errors.has('barangay'),
                          }"
                          v-validate="'required'"
                          :disabled="location.barangay < 1"
                          v-model="form.barangay"
                        >
                          <option value="" selected disabled hidden>
                            Select Barangay
                          </option>
                          <option
                            v-for="(brgy, i) in location.barangay"
                            :key="i"
                            :value="brgy.name"
                            :data-id="brgy._id"
                            v-text="brgy.name"
                          ></option>
                        </select>
                        <div
                          v-if="submitted && errors.has('barangay')"
                          class="invalid-feedback"
                        >
                          This field is required
                        </div>
                      </div>

                      <!-- <div class="mb-3">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          v-model="isChecked"
                        />
                        I accept the
                        <a href="#" @click.prevent="showTC('tnc')" class="ms-1"
                          >Terms & Conditions</a
                        >
                        and
                        <a href="#" @click.prevent="showTC('pp')" class="ms-1"
                          >Privacy Policy</a
                        >
                      </div> -->

                      <div class="mb-3 text-center">
                        <button
                          class="btn px-5 btn-s2s-blue"
                          :disabled="btnLoading || !isChecked"
                          @click.prevent="applyNow()"
                        >
                          <span
                            v-if="btnLoading"
                            class="spinner-border spinner-border-sm pl-5"
                            role="status"
                          >
                            <span class="visually-hidden">Loading...</span>
                          </span>
                          Submit
                        </button>

                        <button
                          class="btn btn-link ms-2"
                          @click.prevent="portRelease()"
                        >
                          Cancel
                        </button>
                      </div>

                      <div class="text-center">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          v-model="isChecked"
                        />
                        I expressly consent to the processing of my personnal
                        information as outlined in the
                        <a href="#" @click.prevent="showTC('pp')" class="ms-1"
                          >Privacy Policy</a
                        >
                        and
                        <a href="#" @click.prevent="showTC('tnc')" class="ms-1"
                          >Terms & Conditions</a
                        >
                        which I read and understood
                      </div>

                      <div class="text-center mt-3">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          v-model="isChecked1"
                        />
                        I agree to receive exclusive newsletter and marketing
                        promotional campaigns.
                      </div>
                      <!-- <div class="text-center" style="margin-left: -32px">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          v-model="ppIsChecked"
                        />
                        I agree to the
                        <a href="#" @click.prevent="showTC('pp')" class="ms-1"
                          >Privacy Policy</a
                        >
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Toast ref="toast" bgClass="bg-danger" position="top-0 end-0" />
    <TermsAndConditions ref="termsAndConditions" />
    <OTPModal ref="otpModal" />
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import Toast from "../components/Toast.vue";
import TermsAndConditions from "../components/TermsAndConditions.vue";
import OTPModal from "../components/OTPModal.vue";
import moment from "moment";

export default {
  components: {
    // ModalMaps,
    Toast,
    TermsAndConditions,
    OTPModal,
  },

  data() {
    return {
      loader: {
        loader: null,
        isLoading: true,
        fullPage: true,
      },

      stepOneDone: false,

      modalMaps: {
        isShow: false,
      },

      civilStatus: ["Single", "Married", "Divorced", "Widowed"],

      location: {
        regions: [],
        provinces: [],
        cities: [],
        barangay: [],
      },

      form: {
        firstName: "",
        middleName: "",
        lastName: "",
        gender: "MALE",
        mobileNumber: "",
        emailAddress: "",
        address: "",
        barangay: "",
        city: "",
        region: "",
        province: "",
        citizenship: "FILIPINO",
        birthdate: "",
        civilStatus: "SINGLE",
        motherFirstName: "xx",
        motherLastName: "xx",
        motherMiddleName: "xx",
        area: "",
        village: "",
        retailerId: "639279999999",
      },

      submitted: false,
      btnLoading: false,

      isChecked: false,
      isChecked1: true,
      ppIsChecked: false,

      birthday: {
        month: "",
        date: "",
        year: "",
      },

      months: [
        {
          name: "January",
          value: "1",
        },
        {
          name: "February",
          value: "2",
        },
        {
          name: "March",
          value: "3",
        },
        {
          name: "April",
          value: "4",
        },
        {
          name: "May",
          value: "5",
        },
        {
          name: "June",
          value: "6",
        },
        {
          name: "July",
          value: "7",
        },
        {
          name: "August",
          value: "8",
        },
        {
          name: "September",
          value: "9",
        },
        {
          name: "October",
          value: "10",
        },
        {
          name: "November",
          value: "11",
        },
        {
          name: "December",
          value: "12",
        },
      ],
    };
  },

  methods: {
    async applyNow() {
      if (!this.isChecked) {
        return;
      }

      this.submitted = true;
      this.btnLoading = true;
      const s = await this.$validator.validate().then((valid) => {
        return valid;
      });

      if (!s) {
        this.toast("Please fill in all required fields.");
        this.btnLoading = false;
        return false;
      }

      const form = { ...this.form };

      this.form.campaign = this.isChecked1;
      form.mobileNumber = await this.reFormatNumber(form.mobileNumber);
      form.area = form.barangay;
      form.birthdate = `${this.birthday.year}-${(
        "0" + this.birthday.month
      ).slice(-2)}-${("0" + this.birthday.date).slice(-2)}`;

      if (this.getIsCod) {
        // const checkMobileNumber = await this.checkMobileNumber(
        //   form.mobileNumber
        // );

        // if (!checkMobileNumber.data.status) {
        //   this.$swal("Error", checkMobileNumber.data.message, "error");
        //   this.submitted = false;
        //   this.btnLoading = false;
        //   return;
        // }

        const smsResponse = await this.$refs.otpModal.open(
          await this.reFormatNumber(form.mobileNumber)
        );
        console.log(smsResponse);

        if (smsResponse) {
          setTimeout(() => {
            this.$store.dispatch("ADD_APPLICATIONFORMDATA", form).then(() => {
              this.$router.push({ path: "review-application" });
            });
          }, 500);
        }
        return;
      }

      // if online payment
      setTimeout(() => {
        this.$store.dispatch("ADD_APPLICATIONFORMDATA", form).then(() => {
          this.$router.push({ path: "review-application" });
        });
      }, 500);
      // console.log(form);
      // form.napCode = this.getPreData.nap;
      // form.referenceNumber = this.getPreData.referenceNumber;
      // console.log(form);
    },

    async portRelease() {
      this.loader.loader = this.$loading.show({
        // Optional parameters
        container: this.loader.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
      });

      await this.$api
        .post("/port-release", {
          referenceNumber: this.getRefNumber,
        })
        .then(() => {
          // const res = response.data;
          // console.log(res);
          this.$store.dispatch("ADD_APPLICATIONFORMDATA", {}).then(() => {
            this.loader.loader.hide();
            this.$router.push({ path: "/" });
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },

    // async reFormatNumber(num) {
    //   return await num.replace(/\s+/g, "").replace("+", "");
    // },

    async getLocation(base = "regions", params = "") {
      await this.$location
        .get(`${base}?${params}`)
        .then((response) => {
          const res = response.data.data;
          if (base === "regions") {
            this.location.regions = res.regions;
          }
          if (base === "provinces") {
            this.location.provinces = res.provinces;
          }
          if (base === "cities") {
            this.location.cities = res.cities;
          }
          if (base === "barangay") {
            this.location.barangay = res.baranggay;
          }
          // return (res);
        })
        .catch((e) => {
          console.log(e);
          this.loadingBtn = false;
        });
    },

    regionSelected(e) {
      const regionId = e.target ? e.target.selectedOptions[0].dataset.id : e;
      this.getLocation("provinces", `regionId=${regionId}`);
      this.form.province = "";
      this.form.city = "";
      this.form.barangay = "";
    },

    provinceSelected(e) {
      const provinceId = e.target ? e.target.selectedOptions[0].dataset.id : e;
      this.getLocation("cities", `provinceId=${provinceId}`);
      this.form.city = "";
      this.form.barangay = "";
    },

    citySelected(e) {
      const cityId = e.target ? e.target.selectedOptions[0].dataset.id : e;
      this.getLocation("barangay", `cityId=${cityId}`);
      // this.form.city = ""
      this.form.barangay = "";
    },

    async portReserve() {
      this.submitted = true;
      this.btnLoading = true;
      const s = await this.$validator.validate().then((valid) => {
        return valid;
      });

      if (!s) {
        this.toast("Please fill in all required fields.");
        this.btnLoading = false;
        return false;
      }

      const payload = {
        firstName: this.form.firstName,
        lastName: this.form.lastName,
        lat: this.getCenter.lat,
        long: this.getCenter.lng,
        online: true,
      };

      await this.$api
        .post("/acquisition/port-reservation", payload)
        .then((response) => {
          this.submitted = false;
          this.btnLoading = false;
          const res = response.data;
          console.log(res);

          if (!res.status) {
            this.$store.dispatch("ADD_NOT_SERVICEABLE", true).then(() => {
              this.$router.push({ path: "not-serviceable" });
            });
            return;
          }

          this.$store
            .dispatch("ADD_REFNUMBER", res.data.referenceNumber)
            .then(() => {
              // this.$router.push({ path: "submitted" });
              this.$store.dispatch("ADD_DATAPACK", {});
              this.stepOneDone = true;
            });

          // this.$store.dispatch("ADD_PREDATA", res.data);
          // this.$store.dispatch("ADD_APPLICATION_FORM", true).then(() => {
          //   this.$router.push({ path: "application-form" });
          // });
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async toast(message) {
      await this.$refs.toast.open({ message: message });
    },

    async showTC(type) {
      await this.$refs.termsAndConditions.open(type);
    },

    // async showOtp() {
    //   await this.$refs.otpModal.open();
    // },

    async checkMobileNumber(mobileNumber) {
      const res = await this.$api.post("/online/cod/check-mobile ", {
        mobileNumber: this.reFormatNumber(mobileNumber),
      });

      // console.log(res);
      return res;
    },

    reFormatNumber(num) {
      // return num.replace(/\s+/g, "").replace("0", "");
      if (num && num.indexOf("63") == 0) {
        return num.replace(/\s+/g, "");
      }
      return "63" + num.substring(1).replace(/\s+/g, "");
    },
  },

  created() {
    this.getLocation();

    if (this.getIsCod) {
      this.stepOneDone = true;
    }

    // const accountNum = localStorage.getItem("accNum");
    // if (accountNum) {
    //   this.accountNumber = accountNum;
    // }
  },

  computed: {
    getCenter() {
      return this.$store.getters.GET_CENTER;
    },

    getCurrentPlace() {
      return this.$store.getters.GET_CENTER_PLACE;
    },

    getPreData() {
      return this.$store.getters.GET_PREDATA;
    },

    getRefNumber() {
      return this.$store.getters.GET_REFNUMBER;
    },

    getIsCod() {
      return this.$store.getters.GET_ISCOD;
    },

    years() {
      const year = moment().format("YYYY");
      const minYear = moment().subtract(75, "years").format("YYYY");
      return Array.from(
        { length: year - minYear },
        (value, index) => parseInt(minYear) + 1 + index
      );
    },

    getOTPVerified() {
      return this.$store.getters.GET_OTPVERIFID;
    },
  },

  mounted() {
    const appData = this.$store.getters.GET_APPLICATIONFORMDATA;
    // this.showOtp();
    const isEmpty = Object.keys(appData).length === 0;
    if (!isEmpty) {
      // console.log("Asdasd")
      this.stepOneDone = true;
      setTimeout(() => {
        const currentData = appData;
        const form = this.form;
        const region = this.location.regions.filter((e) => {
          return e.name === currentData.region;
        });
        this.regionSelected(region[0]._id);

        setTimeout(() => {
          const province = this.location.provinces.filter((e) => {
            return e.name === currentData.province;
          });
          this.provinceSelected(province[0]._id);
          console.log(province[0]._id);
        }, 600);

        setTimeout(() => {
          const city = this.location.cities.filter((e) => {
            return e.name === currentData.city;
          });
          this.citySelected(city[0]._id);
          this.form.city = city[0].name;
          // console.log( this.location.cities)
        }, 700);

        setTimeout(() => {
          // const barangay = this.location.barangay.filter((e) => {
          //   return e.name === currentData.barangay;
          // });
          this.form.barangay = currentData.barangay;
          // console.log(barangay)
          // thi
          // this.citySelected(barangay[0]._id);
          // console.log( this.location.cities)
        }, 800);

        Object.keys(form)
          .filter((key) => key in currentData)
          .forEach((key) => {
            if (key == "birthdate") {
              const bday = currentData[key].split("-");
              this.birthday.year = bday[0];
              this.birthday.month = parseInt(bday[1], 10);
              this.birthday.date = bday[2];
            } else {
              form[key] = currentData[key];
            }
          });
      }, 500);
    }
  },

  validations: {
    form: {
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      // gender: "MALE",
      mobileNumber: {
        required,
      },
      emailAddress: {
        required,
      },
      address: {
        required,
      },
      barangay: {
        required,
      },
      city: {
        required,
      },
      region: {
        required,
      },
      province: {
        required,
      },
      // village:Daan Tubo
      // napCode:QCY001 LP148 NP3-4-5
      // referenceNumber:S2S_1638525314163
      month: {
        required,
      },
      date: {
        required,
      },
      year: {
        required,
      },
    },
  },
};
</script>

<style scoped>
/* @import "../assets/css/reset.css";
@import "../assets/css/sweetalert2.min.css";
@import "../assets/css/superwheel.min.css"; */
</style>
